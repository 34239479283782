<template>
  <div id="cockpit-electricity" class="cockpit-electricity">
    <!-- <PanelHeader></PanelHeader> -->
    <!-- <PanelNav></PanelNav>  -->
    <div id="main">
      <div class="content-left">
        <div class="title">
          <span>场站概况</span>
        </div>
        <ul class="station">
          <li>
            <img src="../../assets/images/cockpit/transformer.png" alt="" />
            <div class="station-text">
              <p style="color: #02b184">
                {{ stationOverview.numberTransformers }}
              </p>
              <span>变压器台数</span>
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/grade.png" alt="" />
            <div class="station-text">
              <p style="color: #ef7f3e">{{ stationOverview.levelVoltage }}</p>
              <span>电压等级(kv)</span>
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/capacity.png" alt="" />
            <div class="station-text">
              <p style="color: #bccaf3">{{ stationOverview.ratedCapacity }}</p>
              <span>额定容量(kVA)</span>
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/form.png" alt="" />
            <div class="station-text">
              <p style="color: #ffc74a; font-size: 14px">
                {{ stationOverview.formDistribution }}
                <!-- 箱变（强电间） -->
              </p>
              <span>配电类型</span>
            </div>
          </li>
        </ul>
        <div class="title" style="margin-top: 17px">
          <span>运行参数</span>
        </div>
        <div class="all-parameter">
          <div class="no-data" v-if="stationParam.length === 0">暂无数据</div>
          <div v-else>
            <ul v-for="(item, index) in stationParam" :key="index" class="run-parameter">
              <li>
                <div class="transformer-name">
                  <img src="../../assets/images/cockpit/voltage.png" alt="" />
                  <div>变压器</div>
                </div>

                <ul class="parameter">
                  <div class="flex-horizontal-between">
                    <!-- 有功功率 -->
                    <li>
                      <p class="key" style=" white-space: nowrap">有功功率</p>
                      <span style="width: 120px; white-space: nowrap">
                        {{ formatReactivePower(item.activePower) }}
                        kWh</span>
                    </li>
                    <li>
                      <p class="key">变压器温度</p>
                      <span>{{
                        formatReactivePower(item.temperatureTransformer)
                      }}℃</span>
                    </li>
                  </div>
                  <div class="flex-horizontal-between">
                    <li style="
                        width: 120px;
                        display: flex;
                        justify-content: center;
                      ">
                      <p class="key" style="width: 100px; white-space: nowrap">无功功率</p>
                      <span style="width: 140px; white-space: nowrap">
                        {{
                          formatReactivePower(item.reactivePower)
                        }}
                        kVar</span>
                    </li>
                    <li>
                      <p class="key" style="width: 80px; white-space: nowrap">变压器负载率</p>
                      <span>{{ formatReactivePower(item.loadTransformer) }}%</span>
                    </li>

                  </div>
                  <div class="flex-horizontal-between">
                    <li style="
                        width: 120px;
                        display: flex;
                        justify-content: flex-start;
                      ">
                      <p class="key" style=" white-space: nowrap">视在功率</p>
                      <span>{{ formatReactivePower(item.apparentPower) }}KVA</span>
                    </li>
                  </div>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="title" style="margin-top: 36px">
          <span>用电情况</span>
        </div>
        <ul class="electricity-usage">
          <li class="electricity-text">
            <img src="../../assets/images/cockpit/electricity-usage.png" alt="" />
            <div>用电情况</div>
          </li>
          <li class="electricity-data">
            <div class="electricity-day">
              <div class="electricity-title">
                <img src="../../assets/images/cockpit/electricity-day.png" alt="" />
                <span>当日用电</span>
              </div>
              <div class="day-value">
                {{ Number(electricityUsage.dayElectricityConsumption).toFixed(2) }}
                <span>(kWh)</span>
              </div>
            </div>
            <div class="electricity-month">
              <div>
                <div class="electricity-title">
                  <img src="../../assets/images/cockpit/electricity-month.png" alt="" />
                  <span>当月用电</span>
                </div>
                <div class="month-value">
                  {{ Number(electricityUsage.monthElectricityConsumption).toFixed(2) }}
                  <span>(MWh)</span>
                </div>
              </div>

              <!-- <img
                src="../../assets/images/cockpit/divider.png"
                alt=""
                class="divider"
              /> -->
              <div class="decline">
                <span>{{
                    Number(electricityUsage.monthElectricityConsumptionRingRatio).toFixed(2)
                  }}</span>
                <div v-if="electricityUsage.monthElectricityConsumptionRingRatio">
                  <img v-if="
                    electricityUsage.monthElectricityConsumptionRingRatio < 0
                  " style="margin: 0 3px" src="../../assets/images/cockpit/electricity-rise.png" alt="" />
                  <img v-else style="margin: 0 3px" src="../../assets/images/cockpit/electricity-drop.png" alt="" />
                  <span>%</span>
                  <p>环比</p>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div class="middle">
        <div class="bus-stop" @click="openDialog">场站</div>
        <div class="station-details">
          <div class="arrow"></div>
          <div class="pop-ups">
            <div class="dialog-title">
              <img src="../../assets/images/cockpit/mark.png" alt="" />
              <p>{{ stationInfo.stationName }}</p>
            </div>
            <ul class="dialog-content">
              <li>
                <div>所属分中心</div>
                <p>{{ stationInfo.companyName }}</p>
              </li>
              <li>
                <div>产权单位名称</div>
                <p>{{ stationInfo.propertyName }}</p>
              </li>
              <li>
                <div>场站编码</div>
                <p>{{ stationInfo.stationNumber }}</p>
              </li>
              <li>
                <div>设备总数</div>
                <p>{{ stationInfo.equipmentCount }}</p>
              </li>
              <li>
                <div>当月用电量</div>
                <p>{{ stationInfo.monthlyElectricityQuantity }}KWh</p>
              </li>
              <li>
                <div>场站占地面积</div>
                <p>{{ stationInfo.coverArea }}m²</p>
              </li>
              <li>
                <div>详细地址</div>
                <p>{{ stationInfo.address }}</p>
              </li>
              <li v-if="stationInfo.stationFile">
                <div>平面图</div>
                <img @click="openPmt"  style="cursor:pointer;" src="../../assets/images/cockpit/plan-view.png" alt="" />
              </li>
              <!-- <li>
              <button>查看平面图</button>
            </li> -->
            </ul>
          </div>
        </div>
        <div @mousedown="startDrag" class="dialogPmt" style="cursor:pointer;" v-if="pmtDialog" @mousewheel="mousewheel">
          <div class="close2" style="cursor:pointer;">
            <!-- <img
              src="../../assets/images/dialog/close2.png"
              alt=""
              @click="pmtDialog = false;wheelDelta=0;"
            /> -->
            <el-tag type="warning" @click="pmtDialog = false;wheelDelta=0;">关闭</el-tag>
            <!-- <span>关闭</span> -->
          </div>
          <div ref="pmtDiv" style="width: 1100px;
            height: 800px;
            overflow: hidden;
            margin: 20px auto;
            position: relative;">
            <img ref="draggableImage" :src="stationInfo.stationFile" draggable="true" :style="{
              top: top + 'px',
              left: left + 'px',
              position: 'absolute',
              transform: transform()
            }" width="100%" height="80%" />
          </div>
        </div>
        <div v-show="screenDialog" class="dialog">
          <div class="close">
            <img src="../../assets/images/dialog/close.png" style="z-index:999;" alt="" @click="screenDialog = false" />
          </div>
          <div class="screen-title">请选择要查询的场站</div>
          <div class="screen-subtitle" style="margin-top: 17px">
            <img src="../../assets/images/dialog/branch.png" alt="" />
            <span>分中心</span>
          </div>
          <div style="height: 150px">
            <ul class="screen-content">
              <li v-for="(item, index) in branchData" :style="{ color: item.id === branchId ? '#ffc74a' : '' }"
                :key="index" @click="branchClick(item)">
                {{ item.companyName }}
              </li>
            </ul>
          </div>

          <div class="screen-subtitle">
            <img src="../../assets/images/dialog/property.png" alt="" />
            <span>产权单位</span>
          </div>
          <div style="height: 150px">
            <ul class="screen-content">
              <li class="property" v-for="(item, index) in propertyData" :key="index" @click="propertyClick(item)"
                :style="{ color: item === propertyId ? '#ffc74a' : '' }">
                {{ item }}
              </li>
            </ul>
          </div>

          <div class="station-title">
            <div class="screen-subtitle">
              <img src="../../assets/images/dialog/station.png" alt="" />
              <span>场站</span>
            </div>
            <div class="search">
              <el-input placeholder="请根据场站名称进行搜索" v-model="stationName"></el-input>
              <el-button type="primary" icon="el-icon-search" @click="queryStation">搜索</el-button>
            </div>
          </div>
          <div style="height: 150px">
            <ul class="screen-content">
              <li class="all-station" v-for="(item, index) in stationData" :key="index" @click="stationClick(item)">
                {{ item.stationName }}
              </li>
            </ul>
          </div>

          <!-- <div class="dialog-footer">
          <button>取消</button>
          <button>确定</button>
        </div> -->
        </div>
        <ul class="weather">
          <!-- <li>
            <img src="../../assets/images/cockpit/wind.png" alt="" />
          </li>
          <li>
            <img src="../../assets/images/cockpit/rain.png" alt="" />
          </li> -->
        </ul>
      </div>
      <div class="content-right">
        <div class="title" style="margin-top: 29px">
          <span>告警通知</span>
        </div>
        <ul class="alert">
          <div class="flex-horizontal-between link">
            <li @click="goAlarmList('4')">
              <img src="../../assets/images/cockpit/special.png" alt="" />
              <div class="alert-text">
                <p style="color: #c92749">{{ specialAlarm.totalAlarm || 0 }}</p>
                <span>特别重大告警</span>
              </div>
            </li>

            <li @click="goAlarmList('3')">
              <img src="../../assets/images/cockpit/major.png" alt="" />
              <div class="alert-text">
                <p style="color: #c92749">{{ majorAlarm.totalAlarm || 0 }}</p>
                <span>重大告警</span>
              </div>
            </li>
          </div>
          <div class="flex-horizontal-between link">
            <li @click="goAlarmList('2')">
              <img src="../../assets/images/cockpit/serious.png" alt="" />
              <div class="alert-text">
                <p style="color: #f6cd29">{{ seriousAlarm.totalAlarm || 0 }}</p>
                <span>严重告警</span>
              </div>
            </li>
            <li @click="goAlarmList('1')">
              <img src="../../assets/images/cockpit/ordinary.png" alt="" />
              <div class="alert-text">
                <p style="color: #51a1ff">{{ averageAlarm.totalAlarm || 0 }}</p>
                <span>一般告警</span>
              </div>
            </li>
          </div>
        </ul>
        <div class="title" style="margin-top: 5px">
          <span>设备情况统计</span>
        </div>
        <ul class="equipment">
          <li>
            <img src="../../assets/images/cockpit/pdt.png" alt="" />
            <div>
              配电图数量
              <span style="color: #28c39e">{{
                equipment.distributionChart
              }}</span>
              个
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/syt.png" alt="" />
            <div>
              摄像头数量
              <span style="color: #ffc74a">{{
                equipment.shexiangtou || 0
              }}</span>
              个
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/byqsb.png" alt="" />
            <div>
              变压器数量
              <span style="color: #2e8ff6">{{ equipment.bianyaqi }}</span> 个
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/gyg.png" alt="" />
            <div>
              高压柜数量
              <span style="color: #da1212">{{ equipment.gaoyace }}</span> 个
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/dyg.png" alt="" />
            <div>
              低压柜数量
              <span style="color: #ef7f3e">{{ equipment.diyace }}</span> 个
            </div>
          </li>
          <li>
            <img src="../../assets/images/cockpit/zlp.png" alt="" />
            <div>
              直流屏数量
              <span style="color: #ae56f1">{{ equipment.zhiliuping }}</span> 个
            </div>
          </li>
        </ul>
        <div class="title">
          <span>环境情况</span>
        </div>
        <div class="environment">
          <ul class="pollution">
            <li>
              <div>
                <span>{{ envStation.yanwu || "无" }}</span>
              </div>
              <p>烟感</p>
            </li>
            <li>
              <div>
                <span>{{ envStation.shuijin || "无" }}</span>
              </div>
              <p>水浸</p>
            </li>
            <li>
              <div>
                <span>{{ envStation.zaoshengfenbei || 0 }}</span><span>dB/m</span>
              </div>
              <p>噪声</p>
            </li>
          </ul>
          <ul class="humidity">
            <li>
              <img src="../../assets/images/cockpit/temperature.png" alt="" />
              <div>
                <span>{{ envStation.wendu || 0 }}</span>
                <p>温度 ℃</p>
              </div>
            </li>
            <li>
              <img src="../../assets/images/cockpit/humidity.png" alt="" />
              <div>
                <span>{{ envStation.shidu || 0 }}</span>
                <p>湿度 RH</p>
              </div>
            </li>
          </ul>
        </div>
        <div class="title">
          <span>当日用能情况</span>
        </div>
        <div id="chart"></div>
      </div>
    </div>
  </div>
</template>
<script>
import PanelHeader from "../../components/header.vue";
import PanelNav from "../../components/panelNav.vue";
import * as echarts from "echarts";
import { monitoringLeftApi } from "@/api/monitoringLeft.js";
import { stationInfoApi } from "@/api/stationInfo.js";
import eventBus from "@/api/NoticeEventBus.js";
export default {
  components: { PanelHeader, PanelNav },
  data() {
    return {
      screenDialog: false,
      pmtDialog: false,
      dragging: false,
      startX: 0,
      startY: 0,
      offsetX: 0,
      top: 0,
      left: 0,
      offsetY: 0,
      imageStyle: {
        position: 'absolute',
        cursor: 'pointer'
      },
      Xscaling: 1,
      Yscaling: 1,
      //初始缩放大小
      wheelDelta:0,
      transform() {
        return `scale(${this.Xscaling}, ${this.Yscaling})`
      },
      stationId: "",
      branchId: "",
      propertyId: "",
      stationName: "",
      branchData: [],
      propertyData: [],
      stationData: [],
      stationInfo: {},
      equipment: {
        pdt: "", //配电图
        sxt: "", //摄像头
        byq: "", //变压器
        gyg: "", //高压柜
        dyg: "", //低压柜
        zlp: "", //直流屏
      },
      electricityUsage: {},
      averageAlarm: {}, //告警情况统计
      seriousAlarm: {}, //告警情况统计
      majorAlarm: {}, //告警情况统计
      specialAlarm: {}, //告警情况统计
      stationOverview: {},
      stationAlarm: [],
      stationParam: [],
      envStation: {}, //温湿水噪
    };
  },
  created() {
    this.$store.commit("increment", "驾驶舱-网");
    this.$store.commit("selectChild", "");
    this.$store.commit("selectChildren", "");
  },
  mounted() {

    this.queryStation();
    this.stationId = sessionStorage.getItem("stationId");
    this.init();
  },
  computed: {
    imgStyle() {
      return {
        width: this.isZoomed ? `${this.baseWidth * this.zoomFactor}px` : `${this.baseWidth}px`,
      };
    },
  },
  methods: {
    startDrag(e){
      console.log(e)
            let that = this  
              this.$refs.pmtDiv.onmousemove = function (el) {
                  const ev = el || window.event; // 阻止默认事件
                  ev.preventDefault();
                  that.left += ev.movementX;
                  that.top += ev.movementY;
              };
              this.$refs.pmtDiv.onmouseup = function () {
                  // 鼠标抬起时将操作区域的鼠标按下和抬起事件置为null 并初始化
                  that.$refs.pmtDiv.onmousemove = null;
                  that.$refs.pmtDiv.onmouseup = null;
              };
              if (e.preventDefault) {
                  e.preventDefault();
              } else {
                  return false;
              }
    },
    mousewheel(e) {
      // 阻止默认的滚动行为
        e.preventDefault();
        //缩小
        if(e.wheelDelta == -120){
          if(this.wheelDelta>0){
            this.wheelDelta = this.wheelDelta - 120
            var num= e.deltaY / 1000  //e.deltaY为上下转动时的值为100,-100
            this.Xscaling = this.Xscaling - num 
            this.Yscaling = this.Yscaling - num
          }else{
            return
          }
        }
        //放大
        if(e.wheelDelta == 120){
          if(this.wheelDelta>=0){
            this.wheelDelta = this.wheelDelta + 120
            var num= e.deltaY / 1000  //e.deltaY为上下转动时的值为100,-100
            this.Xscaling = this.Xscaling - num 
            this.Yscaling = this.Yscaling - num
          }else{
            return
          }
        }
    },
    //缩放图片
    zoomImage() {
      this.isZoomed = true;
    },
    restoreImage() {
      this.isZoomed = false;
    },
    //打开平面图
    openPmt() {
      this.Xscaling = 1
      this.Yscaling = 1
      this.top = 0
      this.left = 0
      this.pmtDialog = true
    },
    //保留两位小数 第二位小数进一
    formatReactivePower(value) {
      // const formatted = (Math.ceil(value * 100) / 100).toFixed(3);
      // if(value){

      //   return value.toFixed(3);
      // }else{
      //   return value 
      // }
      return value
    },
    init() {
      this.stationDetails();
      this.queryElectricityUsage();
      this.queryStationAlarm();
      this.queryStationOverview();
      this.queryStationParam();
      this.queryDeviceNum();
      this.queryEnvironment();
      this.drawChart();
    },
    //分中心点击
    branchClick(item) {
      if (this.branchId === item.id) {
        this.branchId = "";
      } else {
        this.branchId = item.id;
      }
      this.queryStation();
    },
    //产权单位点击
    propertyClick(item) {
      if (this.propertyId === item) {
        this.propertyId = "";
      } else {
        this.propertyId = item;
      }
      this.queryStation();
    },
    //场站选择
    stationClick(item) {
      this.stationId = item.id;
      sessionStorage.setItem("stationNumber", item.stationNumber);
      sessionStorage.setItem("stationName", item.stationName);
      sessionStorage.setItem("stationId", this.stationId);
      this.screenDialog = false;
      this.init();
    },
    //获取场站
    queryStation() {
      let params = {
        companyId: this.branchId,
        propertyUnit: this.propertyId,
        stationName: this.stationName,
      };
      monitoringLeftApi.stationDialog(params).then((res) => {
        this.branchData = res.data.companyInfoList;
        this.propertyData = res.data.propertyUnits;
        this.stationData = res.data.stationInfos;
      });
    },
    //告警通知
    queryStationAlarm() {
      let params = {
        stationId: this.stationId,
      };
      stationInfoApi.queryStationAlarm(params).then((res) => {
        if (res.code === 200) {
          if (res.data.length !== 0) {
            res.data.forEach((element) => {
              if (element.level_name === "严重告警") {
                this.seriousAlarm = element; //严重
              }
              if (element.level_name === "一般告警") {
                this.averageAlarm = element; //严重
              }
              if (element.level_name === "重大告警") {
                this.majorAlarm = element; //严重
              }
              if (element.level_name === "特大告警") {
                this.specialAlarm = element; //严重
              }
            });
          }
        }
      });
    },
    //用电情况
    queryElectricityUsage() {
      let params = {
        stationId: this.stationId,
      };
      stationInfoApi.queryElectricityUsage(params).then((res) => {
        if (res.code === 200) {
          this.electricityUsage = res.data;
        }
      });
    },
    //场站概况
    queryStationOverview() {
      let params = {
        stationId: this.stationId,
      };
      stationInfoApi.queryStationOverview(params).then((res) => {
        if (res.code === 200) {
          this.stationOverview = res.data;
        }
      });
    },
    //运行参数
    queryStationParam() {
      let params = {
        stationId: this.stationId,
      };
      stationInfoApi.queryStationParam(params).then((res) => {
        if (res.data && res.code === 200) {
          this.stationParam = res.data;
        }
      });
    },

    //打开场站弹窗
    openDialog() {
      this.screenDialog = !this.screenDialog;
      this.stationName = "";
      this.queryStation();
    },
    //查询场站详情
    stationDetails() {
      monitoringLeftApi
        .getStationDetail({ stationId: this.stationId })
        .then((res) => {
          if (res.code === 200) {
            this.stationInfo = res.data;
            eventBus.$emit("selectedAlarm1", this.stationInfo.stationName);
            this.stationName = "";
            this.queryStation();
            this.screenDialog = false;
          }
        });
      if (!this.screenDialog) {
        this.branchId = null;
        this.propertyId = null;
      }
    },
    //场站设备数量
    queryDeviceNum() {
      monitoringLeftApi
        .statisticsNumber({ stationId: this.stationId })
        .then((res) => {
          if (res.code === 200) {
            this.equipment = res.data;
          }
        });
      // if (res.length !== 0) {
      //   res.forEach((item) => {
      //     if (item.device_name == "distributionChart") {
      //       this.equipment.pdt = item.num;
      //     }
      //     if (item.device_name == "shexiangtou") {
      //       this.equipment.sxt = item.num;
      //     }
      //     if (item.device_name == "bianyaqi") {
      //       this.equipment.byq = item.num;
      //     }
      //     if (item.device_name == "gaoyace") {
      //       this.equipment.gyg = item.num;
      //     }
      //     if (item.device_name == "diyace") {
      //       this.equipment.dyg = item.num;
      //     }
      //     if (item.device_name == "zhiliuping") {
      //       this.equipment.zlp = item.num;
      //     }
      //   });
      // }
    },
    //温湿水噪
    queryEnvironment() {
      monitoringLeftApi.getStationEnv({ id: this.stationId }).then((res) => {
        this.envStation = res;
      });
    },
    drawChart() {
      const chart = echarts.init(document.getElementById("chart"));
      monitoringLeftApi
        .powerStatistics({ stationId: this.stationId })
        .then((res) => {
          let object = res[0];
          let xAxisData = Object.keys(object);
          let seriesData = Object.values(object).map((value) =>
            value === null ? 0 : value
          );
          var option = {
            tooltip: {
              trigger: "axis",
            },
            legend: {
              show: false,
            },
            grid: {
             
             left:'80'
            },
            xAxis: {
              data: xAxisData,
              axisLabel: {
                textStyle: {
                  color: "#BCCAF3", // 设置字体颜色
                  fontSize: 10,
                },
              },
              axisTick: {
                show: false,
              },
              axisLine: {
                show: true, //是否显示轴线
                lineStyle: {
                  color: "#1B3475", //刻度线的颜色
                },
              },
            },
            yAxis: {
              name: "单位:MWh",
              nameTextStyle: {
                color: "#BCCAF3",
              },
              // data: [0,1000,2000,3000,4000,5000,6000],
              axisLabel: {
                textStyle: {
                  color: "#BCCAF3", // 设置字体颜色
                },
              },
              splitLine: {
                show: false,
              },
            },
            series: [
              {
                type: "line",
                stack: "Total",
                smooth: true,
                showSymbol: false,
                lineStyle: {
                  width: 2,
                  color: "#356fba",
                },
                areaStyle: {
                  opacity: 0.4,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    {
                      offset: 0,
                      color: "#356fba",
                    },
                    {
                      offset: 1,
                      color: "rgb(2, 3, 47)",
                    },
                  ]),
                },
                emphasis: {
                  focus: "series",
                },
                data: seriesData,
              },
            ],
          };
          chart.setOption(option);
        });
    },
    // 跳转报警列表
    goAlarmList(alarmId){
      this.$router.push({
        name: 'Alarm',
        query: {
          alarmId
        }
      })
    }
  },
};
</script>
<style scoped>
#main {
  /* 驾驶舱-网 首页main出现滚动条 */
  height: calc(100vh - 135px);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}
.flex-horizontal-between.link li{
  cursor: pointer;
}

#cockpit-electricity {
  width: 100%;
  /* height: 976px; */
  height: 100%;
  background: url("../../assets/images/cockpit/cockpit-background.jpg") no-repeat center;
  background-size: cover;
  font-size: 16px;
  position: relative;
  /* height: 13.26rem; */
  overflow: auto;
}

.cockpit-electricity::-webkit-scrollbar {
  width: 10px;
  background-color: #0a1857;
}

.cockpit-electricity::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.cockpit-electricity::-webkit-scrollbar {
  width: 10px;
  background-color: #0a1857;
}

#main {
  display: flex;
  /* justify-content: space-between; */
}

/* 左 */
.content-left {
  width: 414px;
  margin-top: 32px;
  box-sizing: border-box;
}

.title {
  width: 358px;
  height: 25px;
  background: url("../../assets/images/cockpit/title-background.png") no-repeat center;
  background-size: cover;
  position: relative;
  margin-left: 33px;
}

.title span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  line-height: 25px;
  position: absolute;
  left: 28px;
}

/* 下拉菜单 */
.dropdown-menu {
  position: relative;
}

.menu-content {
  width: 51px;
  background: #08112b;
  border: 1px solid #112680;
  border-radius: 2px;
  transition: 0.3s;
  cursor: pointer;
  box-sizing: border-box;
  display: none;
  padding-top: 10px;
  position: absolute;
  right: 12px;
  top: 28px;
}

.dropdown-menu:hover .menu-content {
  display: block;
}

.menu-content li {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  text-align: center;
  margin-bottom: 8px;
}

/* .menu-content li:hover {
  color: #08112b;
} */
.select {
  width: 57px;
  height: 23px;
  background: url("../../assets/images/cockpit/select-background.png") no-repeat center;
  background-size: cover;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  line-height: 23px;
  position: absolute;
  right: 12px;
  padding-left: 12px;
  box-sizing: border-box;
  cursor: pointer;
}

/* 场站概况 */
.station {
  width: 407px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 33px 0 40px;
  margin-top: 26px;
  box-sizing: border-box;
}

.station li {
  width: 162px;
  height: 74px;
  background: url("../../assets/images/cockpit/station-background.png") no-repeat center;
  background-size: cover;
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.station img {
  width: 52px;
  height: 52px;
  margin-left: 13px;
}

.station-text {
  margin-left: 10px;
}

.station-text p {
  font-size: 22px;
  font-family: DIN;
  font-weight: 400;
  margin: 0;
}

.station-text span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  margin-top: 7px;
}

/* 运行参数  */
.all-parameter {
  height: 357px;
  width: 100%;
  overflow: auto;
}

.all-parameter::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.all-parameter::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

/* .all-parameter::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.all-parameter::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.all-parameter::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
} */
.run-parameter {
  padding: 0 23px 0 20px;
  box-sizing: border-box;
  margin-top: 12px;
}

.run-parameter li {
  display: flex;
}

.no-data {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  text-align: center;
  line-height: 357px;
}

.transformer-name div {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
  text-align: center;
}

.parameter {
  width: 324px !important;
  height: 100px;
  background: url("../../assets/images/cockpit/param-background.png") no-repeat center;
  background-size: cover;
  padding: 18px 15px 18px 15px;
  box-sizing: border-box;
}

.parameter li {
  width: 130px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}

.parameter :not(:first-child) li {
  margin-top: 7px;
}

.parameter .key::after {
  content: ":";
  margin: 0 1px;
}

/* 用电情况 */
.electricity-usage {
  margin-top: 8px;
  display: flex;
}

.electricity-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 20px;
}

.electricity-text img {
  width: 125px;
  height: 128px;
}

.electricity-text div {
  width: 88px;
  height: 40px;
  background: url("../../assets/images/cockpit/text-background.png") no-repeat center;
  background-size: cover;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e8f4ff;
  text-align: center;
  line-height: 40px;
  margin-top: -8px;
}

.electricity-data {
  margin-left: 20px;
}

.electricity-day,
.electricity-month {
  width: 220px;
  height: 71px;
  background: url("../../assets/images/cockpit/data-background.png") no-repeat center;
  background-size: cover;

  box-sizing: border-box;
}

.electricity-day {
  padding: 8px 0 0 21px;
  margin-bottom: 16px;
}

.electricity-month {
  padding: 8px 14px 0 22px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.divider {
  width: 9px;
}

.electricity-title {
  display: flex;
  align-items: center;
}

.electricity-title img {
  width: 20px;
  height: 20px;
}

.electricity-title span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #a7cdec;
}

.day-value {
  font-size: 28px;
  font-family: DIN;
  font-weight: 400;
  color: #3988ed;
  margin-top: 4px;
}

.day-value span,
.month-value span {
  font-size: 12px;
  font-family: Adobe Heiti Std;
  font-weight: normal;
  color: #a7cdec;
}

.month-value {
  font-size: 18px;
  font-family: DIN;
  font-weight: 400;
  color: #02a97c;
  margin-top: 12px;
}

.decline {
  text-align: center;
}

.decline span {
  font-size: 16px;
  font-family: DIN;
  font-weight: 400;
  color: #e8f4ff;
}

.decline p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e8f4ff;
  text-align: center;
}

/* 右边 */
.content-right {
  width: 423px;
  box-sizing: border-box;
}

.content-right .title {
  margin-left: 0;
}

.alert {
  margin-top: 26px;
  padding: 0 51px 0 38px;
}

.alert li {
  width: 162px;
  height: 74px;
  position: relative;
  margin-bottom: 18px;
  background: url("../../assets/images/cockpit/alarm-background.png") no-repeat center;
  background-size: cover;
}

.alert-text {
  width: 72px;
  text-align: center;
  position: absolute;
  left: 79px;
  top: 14px;
}

.alert img {
  position: absolute;
  left: 19px;
  top: 14px;
}

.alert-text p {
  height: 17px;
  font-size: 22px;
  font-family: DIN;
  font-weight: 400;
  margin-bottom: 10px;
}

.alert-text span {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}

/* 设备情况统计 */
.equipment {
  padding: 0 36px 0 36px;
  margin: 8px 0 29px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 3.5rem;
  height: 1.1rem;
  overflow: auto;
}

.equipment::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.equipment::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.equipment div {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}

/* 环境情况 */
.pollution {
  padding: 0 27px 0 24px;
  display: flex;
}

.pollution div {
  width: 140px;
  height: 124px;
  background: url("../../assets/images/cockpit/ambient-background.png") no-repeat center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -16px;
}

.pollution span {
  width: 40px;
  font-size: 18px;
  font-family: DIN;
  font-weight: 400;
  color: #e8f4ff;
  text-align: center;
}

.pollution span:nth-child(2) {
  margin-left: -8px;
}

.pollution p {
  width: 88px;
  height: 40px;
  background: url("../../assets/images/cockpit/font-background.png") no-repeat center;
  background-size: cover;
  text-align: center;
  line-height: 40px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #e8f4ff;
  margin-left: 16px;
}

.humidity {
  margin: 16px 0;
  padding: 0 51px 0 38px;
  display: flex;
  justify-content: space-between;
}

.humidity li {
  position: relative;
}

.humidity div {
  position: absolute;
  left: 82px;
  top: 13px;
}

.humidity p {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #bccaf3;
}

.humidity span {
  font-size: 22px;
  font-family: DIN;
  font-weight: 400;
  color: #bccaf3;
}

/* 位置 */
.middle {
  width: 100%;
  position: relative;
}



.dialog-title {
  display: flex;
  /* margin: 0 26px 21px 28px; */
  margin: 0 26px 12px 28px;
}

.dialog-title img {
  width: 18px;
  height: 18px;
  margin: 4px 8px 0 0;
}

.dialog-title p {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
}

.dialog-content {
  /* margin: 21px 0 0 25px; */
  margin-left: 24px;
}

.dialog-content li {
  display: flex;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-bottom: 12px;
}

.dialog-content div::after {
  content: ":";
  margin: 0 3px;
}

.dialog-content button {
  width: 206px;
  height: 38px;
  background: #3357c4;
  border-radius: 2px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  text-align: center;
  line-height: 38px;
  /* margin-top: 14px; */
  border: 0;
}

.weather {
  position: absolute;
  right: 31px;
  top: 51px;
}

.weather li {
  width: 51px;
  height: 67px;
  /* background: url("../../assets/images/cockpit/weather-background.png")
    no-repeat center;
  background-size: cover; */
  margin-bottom: 16px;
}

.bus-stop {
  width: 52px;
  height: 67px;
  background: url("../../assets/images/cockpit/bus-stop.png") no-repeat center;
  background-size: cover;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  text-align: center;
  box-sizing: border-box;
  padding-top: 41px;
  position: absolute;
  top: 41px;
  left: 32px;
  cursor: pointer;
}

.pop-ups {
  width: 265px;
  height: 369px;
  background: url("../../assets/images/cockpit/eject-background.png") no-repeat center;
  background-size: cover;
  padding-top: 31px;
  box-sizing: border-box;
  /* position: absolute;
  top: 175px;
  right: 98px; */
  /* position: fixed;
  top: 313px;
  right: 539px; */
}

.station-details {
  display: flex;
  position: fixed;
  top: 26%;
  left: 37%;
}

.arrow {
  width: 378px;
  height: 121px;
  background: url("../../assets/images/cockpit/arrow.png") no-repeat center;
  background-size: cover;
  /* position: absolute;
  right: 371px;
  top: 218px; */
  /* position: fixed;
  top: 319px;
  left: 738px; */
}

#chart {
  width: 423px;
  height: 263px;
  margin-top: -20px;
}

.dialog {
  width: 690px;
  height: 714px;
  background: url("../../assets/images/dialog/background.png") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 26.5%;
  top: 4.5%;
  padding: 19px 19px 0 25px;
  box-sizing: border-box;
  z-index: 100;
}

.dialogPmt {
  position: relative;
  overflow: hidden;
  width: 1200px;
  height: 740px;
  background: url("../../assets/images/dialog/background.png") no-repeat center;
  background-size: cover;
  position: absolute;
  left: 0;
  top: 4.5%;
  padding: 19px 19px 0 25px;
  box-sizing: border-box;
  z-index: 100;
}

.close {
  display: flex;
  justify-content: flex-end;
}
.close2{
  display: flex;
  justify-content: flex-end;
}
.close2 img {
  width: 23px;
  height: 23px;
}
.close img{
  width: 11px;
  height: 11px;
}

.screen-title {
  width: 100%;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #ffc74b;
  text-align: center;
}

.screen-subtitle {
  display: flex;
  align-items: center;
  margin: 12px 0 13px 0;
}

.screen-subtitle img {
  width: 20px;
  height: 20px;
}

.screen-subtitle span {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #d9e3ff;
  margin-left: 16px;
}

.screen-content li {
  width: 150px;
  height: 30px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #aed6ff;
  background-color: rgba(14, 100, 217, 0.11);
  margin: 0 7px 8px 0;
  text-align: center;
  line-height: 30px;
  cursor: pointer;
  display: inline-block;
  white-space: nowrap;
  /* 确保文本不会换行 */
  overflow: hidden;
  /* 隐藏超出容器的文本 */
  text-overflow: ellipsis;
  /* 当文本超出容器时显示省略号 */
}

.screen-content {
  height: 1.5rem;
  overflow: auto;
  width: 6.5rem;
  /* display: flex;
  flex-wrap: wrap; */
}

.screen-content::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.screen-content::-webkit-scrollbar-thumb {
  background-color: #003d74;
  border-radius: 5px;
}

.screen-content::-webkit-scrollbar {
  width: 4px;
  background-color: #0a1857;
}

.station-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0 13px 0;
}

.station-title .screen-subtitle {
  margin: 0;
}

.search {
  display: flex;
}

.search :deep() input {
  width: 480px;
  height: 30px;
  background-color: rgba(14, 100, 217, 0.11);
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  border: 0;
  box-sizing: border-box;
}

.search :deep() button {
  width: 75px;
  height: 30px;
  background-color: #176ad2;
  color: #aed6ff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  text-align: center;
  line-height: 30px;
  padding: 0;
  border: 0;
  border-radius: 0;
  margin-left: -10px;
}

.screen-content .all-station {
  width: 205px;
}

.dialog-footer {
  margin: 7px 0 17px 0;
  display: flex;
  justify-content: center;
}

.dialog-footer button {
  width: 76px;
  height: 32px;
  background-color: #176ad2;
  color: #ffffff;
  font-family: Microsoft YaHei;
  font-size: 12px;
  border: none;
}

.dialog-footer button:first-child {
  margin-right: 15px;
  background-color: #092259;
  color: #aed6ff;
}
</style>
