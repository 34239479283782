import {getRequest,monitorGateway} from "@/utils/request";
// 场站
export const stationInfoApi = {
        // 告警通知
        queryStationAlarm: (query) => {
          return getRequest(`${monitorGateway}/home/page/alarm`, query);
      },
         // 用电情况
         queryElectricityUsage: (query) => {
          return getRequest(`${monitorGateway}/home/page/electricity`, query);
      },
         // 场站概况
         queryStationOverview : (query) => {
          return getRequest(`${monitorGateway}/home/page/overview`, query);
      },
         // 运行参数
         queryStationParam: (query) => {
          return getRequest(`${monitorGateway}/home/page/parameters`, query);
      },
};